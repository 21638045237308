<template>
	<div>
		<H5header :type="2"></H5header>
		<div class="imgbox">
			<img src="./image/tt2.png" alt="" class="xiugai">
		</div>

		<div class="title">
			<img src="../../assets/image/registration2.png" alt="">
			<p>
				<span>请认真填写注册资料，如有问题拨打010-63827176与网站技术联系</span>
			</p>
		</div>
		
		<!-- <div class="inputbox">
			<img src="../../assets/image/unit.png" alt="" style="width: 13PX;height: 15PX;">
			<p>单位名称</p>
			<span>*</span>
			<el-input placeholder="请输入" v-model="info.companyName"></el-input>
		</div>
		<div class="inputbox">
			<img src="../../assets/image/user.png" alt="" style="width: 13PX;height: 15PX;">
			<p>联系人</p>
			<span>*</span>
			<el-input placeholder="请输入" v-model="info.contacts"></el-input>
		</div> -->
		<div class="inputbox">
			<img src="../../assets/image/tel.png" alt="">
			<p>手机号</p>
			<span>*</span>
			<el-input placeholder="请输入" v-model="info.phone"></el-input>
			<div class="inputboxbut" @click="startCountdown" :disabled="isCountingDown">{{ buttonText }}</div>
		</div>

		<div class="inputbox">
			<img src="../../assets/image/code.png" alt="" style="width: 13PX;height: 15PX;">
			<p>短信验证码</p>
			<span>*</span>
			<el-input placeholder="请输入" v-model="info.codeNum"></el-input>
		</div>
		
		<div class="inputbox">
			<img src="../../assets/image/password.png" alt="" style="width: 13PX;height: 15PX;">
			<p>密码登录</p>
			<span>*</span>
			<el-input placeholder="请输入" show-password v-model="info.password"></el-input>
		</div>
		
		<div class="inputbox">
			<img src="../../assets/image/confirm.png" alt="" style="width: 13PX;height: 15PX;">
			<p>确认密码</p>
			<span>*</span>
			<el-input placeholder="请输入" show-password v-model="info.confirm"></el-input>
		</div>

		<div class="but" @click="submitto">
			提交
		</div>
	</div>
</template>

<script>
	import H5header from "./components/header2.vue"
	export default {
		name: '',
		components: {
			H5header
		},
		data() {
			return {
				options: [],
				info: {
					companyType: '',
					phone: '',
					password: '',
					confirm: '',
					codeNum:'',
					userType:'user_type_02',
					username:''
				},
				isCountingDown: false,
				remainingSeconds: 0,
				countdownTimer: null,
				isCountingDown2:false
			}
		},
		computed: {
			buttonText() {
				if (this.isCountingDown) {
					return `${this.remainingSeconds} 秒后重新获取`;
				} else {
					return '获取验证码';
				}
			},
		},
		created() {
			this.$getwxsdk('注册' + '-北京市体育竞赛管理和国际交流中心')
		},
		methods: {
			startCountdown() {
				if (this.info.phone == '') {
					return this.$message.error('请输入手机号码')
				}
				const regex = /^1[3456789]\d{9}$/
				if (!regex.test(this.info.phone)) {
					return this.$message.error('手机号码格式错误')
				}
				if(this.isCountingDown2){
					return;
				}
				if (this.isCountingDown) {
					return;
				}
				let data = {
					phone:this.info.phone,
					timeOut:60
				}
				this.isCountingDown2 = true
				this.$api.getPhoneCode(data).then(res => {
					this.isCountingDown = true;
					this.remainingSeconds = 60;
			
					this.countdownTimer = setInterval(() => {
						this.remainingSeconds--;
			
						if (this.remainingSeconds <= 0) {
							this.stopCountdown();
						}
					}, 1000);
				}).catch(error => {
					this.isCountingDown2 = false;
					console.error('Failed to request verification code:', error);
				})
			},
			stopCountdown() {
				clearInterval(this.countdownTimer);
				this.isCountingDown = false;
				this.remainingSeconds = 0;
				this.isCountingDown2 = false;
			},
			beforeDestroy() {
				clearInterval(this.countdownTimer);
			},
			unitType() {
				this.$api.unitType().then(res => {
					this.options = res.data.data.result
				})
			},
			submitto() {
				// if (this.info.companyName == '') {
				// 	return this.$message.error('请输入单位名称')
				// }
				// if (this.info.contacts == '') {
				// 	return this.$message.error('请输入联系人')
				// }
				if (this.info.phone == '') {
					return this.$message.error('请输入手机号码')
				}
				const regex = /^1[3456789]\d{9}$/
				if (!regex.test(this.info.phone)) {
					return this.$message.error('手机号码格式错误')
				}
				if(this.info.codeNum == ''){
					return this.$message.error('请输入验证码')
				}
				
				const pas = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*_-]).{8,16}$/
				if (this.info.password == '') {
					return this.$message.error('请输入密码')
				}
				if (!pas.test(this.info.password)) {
					return this.$message.error('密码长度 8-16位字符，需包括字母、数字、特殊符号(特殊符号为!@#$%^&*)')
				}
				if (this.info.confirm == '') {
					return this.$message.error('请输入确认密码')
				}
				if (!pas.test(this.info.confirm)) {
					return this.$message.error('密码长度 8-16位字符，需包括字母、数字、特殊符号(特殊符号为!@#$%^&*)')
				}
				
				if (this.info.password != this.info.confirm) {
					return this.$message.error('确认密码输入错误，不一致')
				}
				this.info.username = this.info.phone + this.info.userType
				
				let encryptedInfo = {
					companyType:this.$encrypt(this.info.companyType),
					phone: this.$encrypt(this.info.phone),
					password: this.$encrypt(this.info.password),
					confirm: this.$encrypt(this.info.confirm),
					codeNum: this.$encrypt(this.info.codeNum),
					username:this.$encrypt(this.info.username),
					userType:this.$encrypt('user_type_02')
				}
				
				this.$api.groupBasics(encryptedInfo).then(res => {
					this.$message.success('注册成功')
					setTimeout(() => {
						this.$router.push({
							path: '/h5/login'
						})
					})
				}).catch(error => {
					console.log('请求失败:', error.message);
				});
			}
		}
	}
</script>

<style scoped lang="scss">
	.imgbox {
		display: flex;
		justify-content: center;
	}

	.xiugai {
		width: 120PX;
		height: 78PX;
		margin-top: 36PX;
		display: flex;
		justify-content: center;
	}

	.butbox {
		height: 30PX;
		background: #E8F1FF;
		border-radius: 15PX;
		margin: 37PX 35PX 0;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.name {
			font-size: 12PX;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #0576F0;
			line-height: 12PX;
			padding: 9PX 24PX;
		}

		.name1 {
			background: #0576F0;
			border-radius: 15PX;
			color: #fff;
		}
	}

	.title {
		display: flex;
		margin: 28PX 48PX 0;

		img {
			width: 34PX;
			height: 34PX;
		}

		p {
			font-size: 10PX;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #333333;
			display: flex;
			flex-direction: column;
			margin-left: 12PX;
			margin-top: 4PX;
		}
	}

	.inputbox {
		display: flex;
		align-items: center;
		height: 41PX;
		margin: 24PX 35PX 0;
		background: #F1F6FF;
		border-radius: 50PX;

		img {
			width: 10PX;
			height: 16PX;
			margin-left: 16PX;
		}

		p {
			font-size: 12PX;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #333333;
			line-height: 19PX;
			flex-shrink: 0;
			margin-left: 9PX;
		}

		span {
			font-size: 13PX;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: #E90709;
			flex-shrink: 0;
			margin-left: 4PX;
		}

		::v-deep .el-input__inner {
			border: none;
			background: #F1F6FF;
			border-radius: 50PX;
		}

		.inputboxbut {
			flex-shrink: 0;
			margin-right: 11PX;
			padding: 5PX 10PX;
			font-size: 12PX;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #FFFFFF;
			line-height: 19PX;
			background: linear-gradient(to bottom, #60B8FF, #1F6EEE);
			border-radius: 50PX;
		}
	}

	.but {
		height: 45PX;
		margin: 27PX 35PX 0;
		background: linear-gradient(to bottom, #60B8FF, #1F6EEE);
		border-radius: 50PX;
		font-size: 15PX;
		font-family: Microsoft YaHei;
		font-weight: bold;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
	}

	.zhic {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 42PX;

		img {
			width: 72PX;
			height: 72PX;
		}

		p {
			font-size: 12PX;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: #333333;
			margin-top: 19PX;
			margin-bottom: 20PX;
		}
	}
</style>